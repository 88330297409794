/* eslint-disable import/no-extraneous-dependencies */

import moment from 'moment';
import { returnBaggageType } from '../util';
import ParcelModel from '../../models/parcel';
import { BaggageType } from '../../context/AppContext';
import { getDeliveryStatusName } from 'components/ParcelComponent/utils';

type parcelResponseModel = {
  addressFrom: any; // TODO
  addressTo: any; // TODO
  baggageTypes: Array<string>;
  clientAvatarUrl: string;
  clientId: string;
  createdOnTS: string;
  dealType: string;
  departureDateTimeTS: string;
  firstName: string;
  fullUserName: string;
  id: string;
  lastModified: string;
  locationTo: any; // TODO
  offerId: string;
  parcelDescription: string;
  parcelSum: number;
  phoneNumber: string;
  secondName: string;
  thirdName: string;
  userName: string;
  isActual: boolean;
  locationFromPlaceId: string,
  locationToPlaceId: string,
  deliveryStatus: string,
  parcelTitle: string,
  currency: string,
  insured: boolean
};

const createParcelFromRowData = (
  responseItem: parcelResponseModel,
  baggages: Array<BaggageType>,
  t:any
): ParcelModel =>
  new ParcelModel({
    parcelTitle: responseItem.parcelTitle,
    createdOn: moment(responseItem.createdOnTS).format('DD.MM.YYYY HH:mm').toString(),
    date: moment(responseItem?.departureDateTimeTS).format('DD.MM.YYYY HH:mm').toString(),
    id: responseItem.id,
    addressFrom: responseItem.addressFrom?.city,
    addressTo: responseItem.addressTo?.city,
    fio: `${responseItem?.secondName} ${responseItem?.firstName} ${responseItem?.thirdName}`,
    phoneNumber: responseItem?.phoneNumber,
    baggageTypes: responseItem?.baggageTypes.map((item: string, index: number) =>
      returnBaggageType(item, baggages, index)
    ),
    parcelSum: `${responseItem?.parcelSum} ${responseItem.currency}`,
    dealType:responseItem?.dealType,
    isActual:responseItem?.isActual,
    locationFromPlaceId:responseItem?.locationFromPlaceId,
    locationToPlaceId:responseItem?.locationToPlaceId,
    deliveryStatus:getDeliveryStatusName(responseItem?.deliveryStatus,t),
    insured: responseItem?.insured
  });

export const createParcelsListFromRowData = (
  response: Array<parcelResponseModel>,
  baggages: Array<BaggageType>,
  t:any
): Array<ParcelModel> =>
  response.map((item: parcelResponseModel) => createParcelFromRowData(item, baggages,t));

export const createSortedParcelsListFromRowData = (
  response: Array<parcelResponseModel>,
  baggages: Array<BaggageType>,
  t:any
): Array<ParcelModel> =>
  response
    .sort((a, b) => {
      const x = moment(a.createdOnTS);
      const y = moment(b.createdOnTS);
      return y.diff(x);
    })
    .map((item: parcelResponseModel) => createParcelFromRowData(item, baggages,t))
    ;

export default createSortedParcelsListFromRowData;
