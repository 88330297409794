class ParcelModel {
  readonly parcelTitle: string;
  readonly createdOn: string;
  readonly date: string;
  readonly id: string;
  readonly addressFrom: string;
  readonly addressTo: string;
  readonly fio: string;
  readonly phoneNumber: string;
  readonly baggageTypes: Array<string>;
  readonly parcelSum: number;
  readonly dealType: number;
  readonly isActual: boolean;
  readonly locationFromPlaceId: string;
  readonly locationToPlaceId: string;
  readonly deliveryStatus: string;
  readonly insured: boolean;
  
  constructor({
    parcelTitle,
    createdOn,
    date,
    id,
    addressFrom,
    addressTo,
    fio,
    phoneNumber,
    baggageTypes,
    parcelSum,
    dealType,
    isActual,
    locationFromPlaceId,
    locationToPlaceId,
    deliveryStatus,
    insured
  }) {
    this.parcelTitle = parcelTitle;
    this.createdOn = createdOn;
    this.date = date;
    this.id = id;
    this.addressFrom = addressFrom;
    this.addressTo = addressTo;
    this.fio = fio;
    this.phoneNumber = phoneNumber;
    this.baggageTypes = baggageTypes;
    this.parcelSum = parcelSum;
    this.dealType = dealType;
    this.isActual = isActual;
    this.locationFromPlaceId = locationFromPlaceId;
    this.locationToPlaceId = locationToPlaceId;
    this.deliveryStatus = deliveryStatus;
    this.insured = insured;
  }
}

export default ParcelModel
